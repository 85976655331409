export const columns = [
{
    title: '政策名称',
    dataIndex: 'name',
    key: 'name',
    width:150,
    align:'center',
    ellipsis:true
},
{
    title: '开始时间',
    dataIndex: 'validityStart',
    key: 'validityStart',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '结束时间',
    dataIndex: 'validityEnd',
    key: 'validityEnd',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '限制次数',
    dataIndex: 'limitCount',
    key: 'limitCount',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '是否参与折扣',
    dataIndex: 'joinRebate',
    key: 'joinRebate',
    /*width:150,*/
    align:'center',
    ellipsis:true,
    scopedSlots: { customRender: 'joinRebate'}
},
{
    title: '是否参与积分',
    dataIndex: 'joinIntegral',
    key: 'joinIntegral',
    /*width:150,*/
    align:'center',
    ellipsis:true,
    scopedSlots: { customRender: 'joinIntegral'}
},
{
    title: '审核状态',
    dataIndex: 'authStatus',
    key: 'authStatus',
    /*width:150,*/
    align:'center',
    ellipsis:true,
    scopedSlots: { customRender: 'authStatus'}
},
{
    title: '审核人姓名',
    dataIndex: 'authUser',
    key: 'authUser',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '审核时间',
    dataIndex: 'authDate',
    key: 'authDate',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '创建人',
    dataIndex: 'createUser',
    key: 'createUser',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '创建时间',
    dataIndex: 'createTime',
    key: 'createTime',
    width:150,
    align:'center',
    ellipsis:true
},
];


export const ProColumns = [
  {
    title: '商品名称',
    dataIndex: 'name',
    key: 'name',
    /*width:150,*/
    align:'center',
    ellipsis:true
  },
  {
    title: '商品图片',
    dataIndex: 'validityStart',
    key: 'validityStart',
    /*width:150,*/
    align:'center',
    ellipsis:true
  },
  {
    title: '建议零售价',
    dataIndex: 'sellPrice',
    key: 'sellPrice',
    /*width:150,*/
    align:'center',
    ellipsis:true
  },
  {
    title: '品牌',
    dataIndex: 'brandName',
    key: 'brandName',
    /*width:150,*/
    align:'center',
    ellipsis:true
  },
  {
    title: '分类',
    dataIndex: 'categoryName',
    key: 'categoryName',
    /*width:150,*/
    align:'center',
    ellipsis:true
  },
  // {
  //   title: '政策价格',
  //   dataIndex: 'joinIntegral',
  //   key: 'joinIntegral',
  //   /*width:150,*/
  //   align:'center',
  //   ellipsis:true
  // },
  // {
  //   title: '政策折扣率',
  //   dataIndex: 'authStatus',
  //   key: 'authStatus',
  //   /*width:150,*/
  //   align:'center',
  //   ellipsis:true
  // },
  // {
  //   title: '操作',
  //   dataIndex: 'action',
  //   key: 'action',
  //   /*width:150,*/
  //   align:'center',
  //   ellipsis:true,
  //   scopedSlots: { customRender: 'action'}
  // }
]
